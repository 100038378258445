<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="操作人：" label-width="80px" style="width: 320px;">
              <el-input placeholder="请输入姓名" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>

            <el-form-item label="时间选择：" label-width="90px" style="width: 550px;">
              <div>
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.begin_time" type="date"
                  placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date" placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>

            <el-form-item label="分类：" label-width="60px">
              <el-select v-model="formLabelAlign.type" placeholder="请选择分类">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()">数据更新</i>
          </p>
          <div></div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
            <el-table-column prop="name" label="检查名称"></el-table-column>
            <el-table-column prop="status" label="检查状态"></el-table-column>
            <el-table-column prop="department_name" label="检查部门"></el-table-column>
            <el-table-column prop="add_time" label="开始时间"></el-table-column>
            <el-table-column prop="expire_time" label="结束时间"></el-table-column>

            <el-table-column prop="last_score" label="扣分"> </el-table-column>

            <el-table-column prop="name" label="操作">
              <template slot-scope="scope">
                <el-link type="primary" @click="on_particulars(scope.row)">查看详情</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
      <!-- 新建修改弹窗 -->
      <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
        <risklabelredact v-if="dialog_show" ref="risklabelredact" :refbool.sync="dialog_show"
          @way_tableData="way_tableData" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import { evaluationtask } from "../../assets/request/api";
import risklabelredact from "../../components/view_redact/risk_labelredact";
export default {
  name: "Special_standing",
  mixins: [table_minin],
  components: { risklabelredact },
  data() {
    return {
      table_List: [],
      is_extend: true,
      enable_list: [],
      formLabelAlign: {
        name: "",
        id: "",
        is_enable: "",
        type: "",
        begin_time: "",
        end_time: "",
      },
      dialog_show: false,
      title: "",
    };
  },
  created() {
    this.fromData = this.formLabelAlign;
    this.url = evaluationtask;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      this.enable_list = data.enable_list;
    },
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.begin_time
          );
        },
      };
    },
    on_particulars(row) {
      console.log(row);
      let { id, task_id } = row;

      this.$router.push({
        path: "/assess/review_standingparticulars",
        query: {
          id,
          task_id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
